import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  CardHeader,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import React from "react";

const MatchupStatus = (props) => {
  return (
    <TableContainer component={Paper} elevation={3} sx={{ marginBottom: 2 }}>
      <Table sx={{ minWidth: 550}}>
        <TableHead>
          <TableRow>
            <TableCell width={50}>
              <CardHeader
                title={
                  <span style={{ fontWeight: "bold", fontSize: 14 }}>
                    {props.matchup.leagueName}
                  </span>
                }
              />
            </TableCell>
            <TableCell
              align="center"
              width={10}
              sx={{ color: "grey", fontWeight: "bold" }}
            >
              Proj PTS
            </TableCell>
            <TableCell
              align="center"
              width={10}
              sx={{ color: "grey", fontWeight: "bold" }}
            >
              Win %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              width={50}
              sx={{ borderBottom: "none", fontWeight: "bold" }}
            >
            <CardHeader
              avatar = {<Avatar sx = {{width:25, height:25}}>
                <img
                  src={props.matchup.home.logo}
                  width={25}
                  height={25}
                  alt="player dont care LOL"
                ></img>
              </Avatar>}
              title = {`${props.matchup.home.name}(${props.matchup.home.wins}-${props.matchup.home.losses})`}
              titleTypographyProps={props.support === "home" ? {color : 'green'} : {color: 'red'}}
            />
            </TableCell>
            <TableCell
              align="center"
              width={10}
              sx={{ borderBottom: "none", fontWeight: "bold" }}
            >
            {props.matchup.home.points}
            </TableCell>
            <TableCell align="center" width={10} sx={{ borderBottom: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ minWidth: 35, mr: 1 }}>
                  <Typography variant="body2">{`${Math.round([
                    props.matchup.home.percent,
                  ])}%`}</Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={props.matchup.home.percent}
                    color={
                      props.matchup.home.percent < 50 ? "error" : "success"
                    }
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              width={50}
              sx={{ borderBottom: "none", fontWeight: "bold" }}
            >
            <CardHeader
              avatar = {<Avatar sx = {{width:25, height:25}}>
                <img
                  src={props.matchup.away.logo}
                  width={25}
                  height={25}
                  alt="player dont care LOL"
                ></img>
              </Avatar>}
              title = {`${props.matchup.away.name}(${props.matchup.away.wins}-${props.matchup.away.losses})`}
              titleTypographyProps={props.support === "away" ? {color : 'green'} : {color: 'red'}}
            />
            </TableCell>
            <TableCell
              align="center"
              width={10}
              sx={{ borderBottom: "none", fontWeight: "bold" }}
            >
            {props.matchup.away.points}
            </TableCell>
            <TableCell align="center" width={10} sx={{ borderBottom: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ minWidth: 35, mr: 1 }}>
                  <Typography variant="body2">{`${Math.round([
                    props.matchup.away.percent,
                  ])}%`}</Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={props.matchup.away.percent}
                    color={
                      props.matchup.away.percent < 50 ? "error" : "success"
                    }
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MatchupStatus;
