import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function CollapsibleExample() {
  return (
    <div className='navBar'>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
      <Navbar.Brand href="/">
            <img
              alt=""
              src = {require('./DumbozLogo.jpg')}
              width="30"
              height="30"
              className="d-inline-block align-top logo"
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="https://www.youtube.com/c/DumboZ?sub_confirmation=1">Youtube</Nav.Link>
            <NavDropdown title="Kevflix" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/kevflix/register">Register</NavDropdown.Item>
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
              <NavDropdown.Item href="/kevflix/setup">Setup</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://app.plex.tv/">Kevflix Site</NavDropdown.Item>
              <NavDropdown.Item href="">Requests</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Dota" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/dota">Peeper</NavDropdown.Item>
              <NavDropdown.Item href="/dota/map">Map</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/suggestions">Suggestions</Nav.Link>
            <NavDropdown title="Quizzes" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/quizzes/political">Political</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Games" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/games/flappytounge">Flappy Tounge</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/mwkl">MWKL</Nav.Link>
            <Nav.Link href="https://dumboz.myspreadshop.com/">Shop</Nav.Link>
            <Nav.Link href="https://soundcloud.com/dumboz">Soundcloud</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/contactUs">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default CollapsibleExample;

// <Nav.Link eventKey={2} href="#memes">Dank memes</Nav.Link>