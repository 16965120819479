import React from "react";
import { useEffect, useState} from "react";
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'


const quotes = [
"(David) I think of you as a husband",
"I cannot imagine Jake with a pussy",
"You seem awfully afraid of the pains of hell, is there anything you're not telling us?",
"I once ate another mans ass, and i enjoyed it",
"She's got to show she's halfway committed in the relationship.",
"I don't go on second dates; I use bumble for casual sex.",
"I never pay for bumble dates. I'm sorry, I don't pay for women. They aren't prostitutes.",
"Can you, in good faith, claim that you have even an ounce of an idea of what you're talking about",
"The war on drugs was a tie",
"Inflation is good",
"I only do verbal assault",
"Like what Shaquille O'Neal says. Just do it.",
"You know what they say. Just fuck the guy then.",
"Personally I do believe that all animals should die",
"yeah schuck I really just don't like playing Dota with you",
"Imagine if I had to spend all that time inside a **woman**",
"The only pies that you've had are store bought and made with tears",
"We call ourselves the greatest country, yet we can not drunk drive. We call ourselves the land of the free, yet I can not consume alcohol.",
"She got that smegma call it glizzy crust",
"If Jake would've just quoted me as saying 'I would for sure fuck my mom', he would be completely truthful",
"I would for sure fuck my mom if i could",
"No crabs to persecute for their religion in this hellscape",
"The buttered toast on the dick doesn't oh fly away.",
"It's throwing me off cause of the ear-ring",
"You have a crush on her. I'm eating her hair off the shower wall. We are not the same.",
"If Kevin's going to have sex he's just going to *have* sex",
"You trying to eat this bit-pussy out?",
"I wish you guys could hear my farts over discord",
"That's not even a good lewd.",
"Im for the opposite of people pissing in my soup",
"You're getting buuled on a little bit by the memer.",
"Put ____ in a wheelchair.",
"I sniffed the crack, and now I'm ready to go again!",
"Isn't 'je ne sais quoi' just French for poggers?",
"I have Jimmy's Balls on my phone right now",
"Should I put band-aids on my nipples so they don't chafe when I run?",
"Same day shipping.",
"I'm the smart bro.  1 is greater than 6",
"He better have cancer",
"We're sleezes that's what we are",
"Noter's not a word.",
"Jake I bet if you asked Kevin to pee on you he would",
"Ben's muted for the next month if we lose",
"If I put that the textbook is required the university recommends you to buy it.  I put recommended because I recommend you steal it",
"crucify a man for gaming",
"Jimmy is cuck",
"Eight of spades or not the eight of spades?",
"Your mom is a sock, I put my toe in her.",
"Yesterday I tried to commit suicide. I am never doing that shit again -- I almost killed myself!",
];

function isMobile() {
    return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
}



const MainPage = () => {
    const [randomQuote, setQuote] = useState("");
    const roll = Math.floor(Math.random() * 6)

    

        useEffect(()=>{
                //Below just puts random DumbozVideo on page.  Not used anymore but it took me a while to make.

                /*
                fetch('listOfDumbozVideos.txt')
                .then((res)=>res.text())
                .then((data)=>data.split(/\r?\n/))
                .then((arr)=>arr[Math.floor(Math.random() * arr.length)])
                .then((text)=>{
                    //setPath("/"+text);
                    console.log(text);
                });
                */
                
                setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
        // eslint-disable-next-line
        }, []);

    const { width, height } = useWindowSize()
    console.log(roll)

    return (
        <>
        {roll === 0 ? 
        <Confetti
        width={width}
        height={height}
        />
        :
        <></>
        }
        
        <div className="wrapper gradientBody">
        <div className="videoWrapper">
        <div className="videoOverlay"><div id = "videoOverlayLarge">DumboZ</div><div id = "two">
            {roll === 0 ?
            <div id="funVideoOverlaySmall">Congratulations, you are our 10,000th visitor</div>
            :
            <div id="videoOverlaySmall">{randomQuote}</div>
            }
        </div>
        {isMobile() ?
        <div className= "fun" style = {{'width':'90vw', 'font-size': '11vw'}}>This shit is not formatted for mobile.  We do not know any webdevs.</div>
        :
        <></>
        }
        </div>
        <>
        {isMobile() ?
        <></>        
        :
        <video className='fill' autoPlay loop muted playsInline>
            <source id = "backgroundVideo" src="pecker.mp4" type='video/webm' poster = 'peckerPoster.png' />
        </video>
        }
        </>

        </div>
        </div>
        </>
    );
}

export default MainPage;