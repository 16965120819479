import React from "react";
import PlayCard from "./PlayCard";
import { Stack, Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import MatchupStatus from "./MatchupStatus";
import axios from "axios";
import { useEffect, useState, useRef} from "react";

const FantasyGameDay = () => {
  const [plays, setPlays] = useState([
    {
      playType: "TD",
      homeScore: 10,
      homeTeam: "PT",
      awayScore: 14,
      awayTeam: "LA",
      quarter: 4,
      time: "4:02",
      description:
        "John smith and sugma scored a fatty touchdown together LOL. that shit was based afffffffffffff",
      players: [
        {
          name: "John Smith",
          position: "QB",
          team: "PT",
          overallPoints: 5.06,
          pointsOnPlay: 2.45,
          image:
            "https://img.freepik.com/free-photo/portrait-hesitant-man-purses-lips-looks-bewilderment-feels-doubt_273609-16785.jpg",
          color: 'white'
        },
        {
          name: "Sugma Schlong",
          position: "WR",
          team: "PT",
          overallPoints: 10.11,
          pointsOnPlay: 2.12,
          image:
            "https://apiwp.thelocal.com/cdn-cgi/image/format=webp,width=855,quality=75/https://apiwp.thelocal.com/wp-content/uploads/2018/12/6d67730d16af04f3f956389d4cc244af808b8381c23b1e3d218ecd792de14fa8.jpg",
          color: 'white'
        },
      ],
    },
    {
      playType: "FG",
      homeScore: 10,
      homeTeam: "PT",
      awayScore: 14,
      awayTeam: "LA",
      quarter: 4,
      time: "4:02",
      description:
        "John smith and sugma scored a fatty touchdown together LOL. that shit was based afffffffffffff",
      players: [
        {
          name: "John Smith",
          position: "QB",
          team: "PT",
          overallPoints: 5.06,
          pointsOnPlay: 2.45,
          image:
            "https://img.freepik.com/free-photo/portrait-hesitant-man-purses-lips-looks-bewilderment-feels-doubt_273609-16785.jpg",
          ownership:[]
        },
        {
          name: "Sugma Schlong",
          position: "WR",
          team: "PT",
          overallPoints: 10.11,
          pointsOnPlay: 2.12,
          image:
            "https://apiwp.thelocal.com/cdn-cgi/image/format=webp,width=855,quality=75/https://apiwp.thelocal.com/wp-content/uploads/2018/12/6d67730d16af04f3f956389d4cc244af808b8381c23b1e3d218ecd792de14fa8.jpg",
          ownership:[]
        },
      ],
    },
  ]);

  const [matchups, setMatchups] = useState([
    {
      "away": {
        "id": 4,
        "logo": "https://c.tenor.com/nDnttD7l15kAAAAC/king-congratulations.gif",
        "losses": 5,
        "name": "Shart xXKing_BobXx",
        "percent": 94,
        "points": "106.39",
        "wins": 6
      },
      "home": {
        "id": 1,
        "logo": "https://emojis.slackmojis.com/emojis/images/1643514221/1868/clippy.png",
        "losses": 7,
        "name": "Canterbury New Stepfathers",
        "percent": 5,
        "points": "108.85",
        "wins": 4
      },
      "leagueID": 1283278457,
      "leagueName": "Marg Wednesday Kickers League "
    },
    {
      "away": {
        "id": 8,
        "logo": "https://c.tenor.com/w7hN0fU0BQAAAAAC/clash-of-clans-hog-rider.gif",
        "losses": 5,
        "name": "Illinois Large Hogs",
        "percent": 26,
        "points": "113.86",
        "wins": 6
      },
      "home": {
        "id": 3,
        "logo": "https://g.espncdn.com/lm-static/logo-packs/ffl/HelmetAlphabet-ESPN/Helmet-L.svg",
        "losses": 4,
        "name": "Dumpster Fire",
        "percent": 44,
        "points": "122.06",
        "wins": 7
      },
      "leagueID": 1283278457,
      "leagueName": "Marg Wednesday Kickers League "
    },
  ]);

  const [matchupFilter, setMatchupFilter] = useState(Array(3*4).fill('neither'));

  const interval = useRef(null);

  const getPlays = async function(){
    console.log(matchupFilter)
    let filterString = ""
    for (let i = 0; i < matchupFilter.length; i++) {
      filterString += matchupFilter[i] + ",";
    }
    console.log(filterString)
    // const data = await axios.get('http://localhost:9999/mwkl/statUpdates', {params: {'matchups': filterString}});
    const data = await axios.get('https://140.141.193.102:9999/mwkl/statUpdates', {params: {'matchups': filterString}});
    return data['data']
  }

  const getMatchups = async function(){
    // const data = await axios.get('http://localhost:9999/mwkl/matchupUpdates');
    const data = await axios.get('https://140.141.193.102:9999/mwkl/matchupUpdates');
    return data['data']
  }

  useEffect(()=>{
    if(interval !== null){
      clearInterval(interval.current)
    }
    getPlays().then(plays => setPlays(plays))
    interval.current = setInterval(() => {getPlays().then(plays => setPlays(plays))}, 10000);
  },[matchupFilter])

  useEffect(()=>{
    getMatchups().then(matchups => setMatchups(matchups))
    setInterval(() => {getMatchups().then(matchups => setMatchups(matchups))}, 10000);
  },[])


  // Don't know, don't care, don't touch.

  const handleChange = (
    key, value
  ) => {
    let tempAlignment = matchupFilter
    if(value !== null){
      tempAlignment[key] = value
      setMatchupFilter(matchupFilter.map((matchup, i) => {
              // if(i === event.target.name){
              //   return !(matchup)
              // }
              return matchup
      }))
    }
  };

  return (
    <Box display="flex" gap={5} marginTop={3} justifyContent="center">
      <Box sx = {{maxHeight : "95vh", overflow : "scroll"}}>
        {plays && plays.map((play, i) => {
          return <PlayCard play={play} key={i} />;
        })}
      </Box>
      <Box sx = {{maxHeight : "95vh", overflow : "scroll", maxWidth : "50%"}}>
        {matchups.map((matchup, i) => {
          return(<>{matchupFilter[i] !== "neither" ? <MatchupStatus matchup={matchup} key={i} support = {matchupFilter[i]}/> : <></>}</>);
        })}
      </Box>
      <Stack maxWidth="20%">
        {matchups.map((matchup, i) => {
          return(<ToggleButtonGroup
            color="primary"
            value={matchupFilter[i]}
            exclusive
            onChange={(event, value) => handleChange(i, value)}
            aria-label="Platform"
            key = {i}
            name = {i}
          >
            <ToggleButton value="home" width="40px">{matchup.home.name}</ToggleButton>
            <ToggleButton value="neither" width="40px">Neither</ToggleButton>
            <ToggleButton value="away" width="40px">{matchup.away.name}</ToggleButton>
          </ToggleButtonGroup>);
        })}
      </Stack>
    </Box>
  );
}

export default FantasyGameDay;
