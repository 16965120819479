import {Button, Form} from "react-bootstrap";
import { useState, useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";


const Quizzes = () => {
    const subDumboz = () => {
        window.open("https://www.youtube.com/c/DumboZ?sub_confirmation=1");
    };

    const [validated, setValidated] = useState(false);
    const [isChecked, setIsChecked] = useState({});
    const [politicalScore, setScore] = useState(0);

    const onChange = (e) => {
        setIsChecked({ ...isChecked, [e.target.name]: e.target.id });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(isChecked).length === 10) {
            event.stopPropagation();
            calculateScore(isChecked);
            setValidated(true);
            setState(true);
        } else {
            alert('Please answer all questions');
        }
    };

    const calculateScore = (checked) => {
        var right = 0;
        if(checked['group1'] === '1A'){
            right += 3;
        } else {
            right -= 3;
        }

        if(checked['group2'] === '2A'){
            right += 1;
        } else {
            right -= 1;
        }

        if(checked['group3'] === '3A'){
            right += 10;
        } else {
            right -= 3;
        }

        if(checked['group4'] === '4A'){
            right -= 2;
        } else {
            right += 2;
        }

        if(checked['group5'] === '5A'){
            right -= 10;
        } else {
            right += 3;
        }

        if(checked['group6'] === '6A'){
            right -= 3;
        } else {
            right += 3;
        }

        if(checked['group7'] === '7A'){
            right += 3;
        } else {
            right -= 3;
        }

        if(checked['group8'] !== '8A'){
            right -= 3;
        } else {
            right += 4;
        }

        if(checked['group9'] !== '9A'){
            right -= 4;
        } else {
            right += 3;
        }

        if(checked['group10'] === '10A'){
            right -= 0;
        } else {
            right += 0;
        }

        setScore(right);
    }

    const [state, setState] = useState(false);
    const helloRef = useRef(null);
    const goodbyeRef = useRef(null);
    const nodeRef = state ? goodbyeRef : helloRef;

    return(
        <>
        <div style={{
            'textAlign':'center'
        }}>
        <SwitchTransition mode = 'out-in'>
            <CSSTransition 
                key={state}
                nodeRef = {nodeRef}
                addEndListener={(done) => {
                    nodeRef.current.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                <div ref={nodeRef}>
        {validated ? 
        <div className="fader">
        <h1>Your score is right: {politicalScore}</h1>
        </div>
        :
        <div className="fader">
        <h1>Political Compass Quiz</h1>
            <Form noValidate validated={validated} onSubmit = {handleSubmit}>
            Which hand do you jerk off with?
            <br></br>
            <Form.Check
                inline
                label="Left"
                name="group1"
                type='radio'
                id='1A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Right"
                name="group1"
                type='radio'
                id='1B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>


            Should all babies be slaughtered?
            <br></br>
            <Form.Check
                inline
                label="Yes"
                name="group2"
                type='radio'
                id='2A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Of course"
                name="group2"
                type='radio'
                id='2B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            How much do you like ranch?
            <br></br>
            <Form.Check
                inline
                label="Yes"
                name="group3"
                type='radio'
                id='3A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="No"
                name="group3"
                type='radio'
                id='3B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            OG or EG?
            <br></br>
            <Form.Check
                inline
                label="OG"
                name="group4"
                type='radio'
                id='4A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="EG"
                name="group4"
                type='radio'
                id='4B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Are you currently late to a fitting?
            <br></br>
            <Form.Check
                inline
                label="Yes"
                name="group5"
                type='radio'
                id='5A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="No"
                name="group5"
                type='radio'
                id='5B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Ass or Tits?
            <br></br>
            <Form.Check
                inline
                label="Ass"
                name="group6"
                type='radio'
                id='6A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Tits"
                name="group6"
                type='radio'
                id='6B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Squiddies? 
            <br></br>
            <Form.Check
                inline
                label="Titties"
                name="group7"
                type='radio'
                id='7A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Nose"
                name="group7"
                type='radio'
                id='7B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Which DTap song goes harder?
            <br></br>
            <Form.Check
                inline
                label="Rzeady"
                name="group8"
                type='radio'
                id='8A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Sunday"
                name="group8"
                type='radio'
                id='8B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Peanuts or Cracker Pocket?
            <br></br>
            <Form.Check
                inline
                label="Peanuts"
                name="group9"
                type='radio'
                id='9A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="Cracker Pocket"
                name="group9"
                type='radio'
                id='9B'
                required
                onChange={onChange}
            />
            <br></br>
            <br></br>

            Are you subscribed to DumboZ?
            <br></br>
            <Form.Check
                inline
                label="Yes"
                name="group10"
                type='radio'
                id='10A'
                required
                onChange={onChange}
            />
            <Form.Check
                inline
                label="No"
                name="group10"
                type='radio'
                id='10B'
                required
                onClick={subDumboz}
                onChange={onChange}
            />
            <br></br>
            <br></br>
            <Button variant = "primary" type = "submit">
            Submit
            </Button>
            </Form>
            </div>
            }
                </div>
            </CSSTransition>
        </SwitchTransition>
        </div>
        </>
    );
};

export default Quizzes;