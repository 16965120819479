import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import KevflixSignup from './components/kevflix/signup';
import KevflixSetup from './components/kevflix/setupInstructions';
import NavBarMain from './components/navBar';
import About from './components/about';
import HomePage from './components/homepage';
import Dota from './components/dota/dotapage';
import DotaMap from './components/dota/dotaMap';
import DotaStats from './components/dota/dotaStats';
import PoliticalQuiz from './components/quizzes/political'
import Suggestions from './components/suggestions';
import Contact from './components/contactUs';
import MobileReject from './components/mobileReject';
import SGDEHMZKD from './components/SGDEHMZKD';
import FantasyGameDay from './components/fantasyFootball/gameDay';

//TODO: Game page
//TODO: MWKL page
//TODO: Kevflix.live
//TODO: Tournament pages 

function isMobile() {
  return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
}


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<><div className='wrapper'><NavBarMain/><HomePage/></div></>} />
          <Route path = "/about" element={<><NavBarMain/><About/></>} />
          <Route path = "/contactUs" element={<><div className = 'wrapper'><NavBarMain/><Contact/></div></>} />
          <Route path = "/kevflix/register" element={<><div className='wrapper'><NavBarMain/><KevflixSignup/></div></>}/>
          <Route path = "/SGDEHMZKD" element={<SGDEHMZKD />} />
          <Route path = "/kevflix/setup" element={<><div style={{'overflowX': 'clip'}}><NavBarMain/><KevflixSetup/></div></>}/>
          <Route path = "/quizzes/political" element={<><NavBarMain/><PoliticalQuiz/></>}/>
          <Route path = '/suggestions' element = {<><div className='wrapper'><NavBarMain/><Suggestions/></div></>}/>
          <Route path = "/dota" element={<Dota/>} />
          <Route path = "/dota/map" element={<DotaMap/>} />
          <Route path = "/dota/stats" element={<><FantasyGameDay/></>} />
          <Route
              path="/kevflix/about"
              element={<Navigate to="/about" replace />}
          />
          {isMobile() ?
              <>
              <Route path = "/mobile" element={<><NavBarMain/><MobileReject/></>} />
              <Route
                  path="*"
                  element={<Navigate to="/mobile" replace />}
              />
              </>
              :
              <>
              {/* All of the things that are not mobile supported. */ }
              
              </>
          }
        </Routes>        
      </BrowserRouter>
    </>    
  );
}

export default App;
