import {
  Card,
  CardContent,
  Divider,
  Typography,
  Avatar,
  CardHeader,
  Grid,
} from "@mui/material";
import React from "react";

const PlayCard = (props) => {
  function getPlayInfo(play, type) {
    const plays = {
      REC: { abbr: "REC", color: "grey" },
      RUSH: { abbr: "RUSH", color: "grey" },
      PUNT: { abbr: "Punt", color: "grey" },
      K: { abbr: "Punt", color: "grey" },
      TD: { abbr: "TD", color: "green" },
      INTR: { abbr: "INTR", color: "red" },
      PEN: { abbr: "PEN", color: "red" },
      FG: { abbr: "FG", color: "blue" },
      NULL: { abbr: "??", color: "grey" },
    };
    if (play in plays){
      return plays[play][type];
    }else if (type === "abbr"){
      return "???";
    }else{
      return "red";
    }
  }

  return (
    <Card sx={{ maxWidth: 500, marginBottom: 2 }} elevation={3}>
      <CardContent>
        <Grid item xs={12} sm container sx={{ marginBottom: 2 }}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: getPlayInfo(props.play.playType, "color"),
                }}
                gutterBottom
              >
                <span
                  style={{
                    backgroundColor: getPlayInfo(props.play.playType, "color"),
                    color: "white",
                    padding: 4,
                    borderRadius: 2,
                    marginRight: 6,
                  }}
                >
                  {getPlayInfo(props.play.playType, "abbr")}
                </span>
                {props.play.playType.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: "grey", fontSize: 13, fontWeight: "bold" }}
            >
              {`Q${props.play.quarter}:${props.play.time} (${props.play.homeTeam} ${props.play.homeScore} - ${props.play.awayTeam} ${props.play.awayScore})`}
            </Typography>
          </Grid>
        </Grid>
        {props.play.players.map((player, i) => {
          return (
            <Grid item xs={12} sm container key={i}>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <CardHeader
                    sx={{background: `linear-gradient(90deg, ${player.color} 0%, white 60%)`,
                          m:.25}}
                    avatar={
                      <Avatar>
                        <img
                          src={player.image}
                          width={75}
                          height={50}
                          alt="player dont care LOL"
                        ></img>
                      </Avatar>
                    }
                    title={
                      <span style={{ fontWeight: "bold", fontSize: 14 }}>
                        {player.name}
                      </span>
                    }
                    subheader={
                      <>{player.position} - {player.team} {player.ownership ? <> {player.ownership.length ? player.ownership.map((ownership, j) => {
                        return(
                          <img
                            src={ownership}
                            width={20}
                            height={20}
                            alt="owner dont care LOL"
                            style = {{borderRadius:"50%"}}
                          ></img>
                        )
                      }) : <></>}</> : <></>}</>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item textAlign={"center"}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold" }}
                  component="div"
                >
                  {player.pointsOnPlay}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ color: "grey", fontSize: 13 }}
                >
                  PTS
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginY: 1, marginX: 2 }}
              />
              <Grid item textAlign={"center"}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold" }}
                  component="div"
                >
                  {player.overallPoints}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ color: "grey", fontSize: 13 }}
                >
                  TOTAL
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Divider />
        <Typography sx={{ color: "grey", marginTop: 2 }} paragraph>
          {props.play.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PlayCard;
