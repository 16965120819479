import React from "react";

const Contact = () => {
  return (
    <>
    <div className="warning">
        Don't
    </div>
    </>
  );
};
  
export default Contact;