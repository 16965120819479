import React from "react";
import { useRef, useEffect, useState } from "react";
import section1 from "../../images/AshTrayInstructions-1.png"
import section2 from "../../images/AshTrayInstructions-2.png"
import section3 from "../../images/AshTrayInstructions-3.png"
import ProgressButton from "./progress";

const KevflixSetup = () => {
  useEffect(()=>{
    listenToScrollEvent();
// eslint-disable-next-line
}, []);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const [scrollPos, setScroll] = useState(0);

  const scrollTo = (ref) => {
    window.scroll({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };


  const listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      });
    });
  }

  const calculateScrollDistance  =  ()  =>  {
    const  scrollTop  =  window.pageYOffset;
    const  winHeight  =  window.innerHeight;
    const  docHeight  =  Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  
    const  totalDocScrollLength = docHeight  -  winHeight;
    const  scrollPosition  =  Math.floor(scrollTop  /  totalDocScrollLength  *  100);
  
    setScroll(scrollPosition);
  }

  return (
    <>
    <div className="ashTrayContainer">
      <div className = "stickyButtons">
       <ProgressButton scroll = {100*scrollPos/40} onClick={() => scrollTo(section1Ref)}>Join Server</ProgressButton>
        <br />
        <ProgressButton scroll = {100*(scrollPos-40)/38} onClick={() => scrollTo(section2Ref)}>Pin Libraries</ProgressButton>
        <br />
        <ProgressButton scroll = {100*(scrollPos-78)/22} onClick={() => scrollTo(section3Ref)}>Change Settings</ProgressButton>
      </div>
      <div ref={section1Ref} className = "ashTrayInstruction">
          <img src={section1} alt="jaja"></img>
      </div>
      <div ref={section2Ref} className = "ashTrayInstruction">
          <img src={section2} alt="jaja"></img>
      </div>
      <div ref={section3Ref} className = "ashTrayInstruction">
          <img src={section3} alt="jaja"></img>
      </div>
    </div>
    </>
  );
}

export default KevflixSetup;