import React, { useState } from "react";
import sniper from "../../images/sniper.jpg"
import assassinate from "../../sounds/Assassinate.mp3.mpeg"
import thanks from "../../sounds/thanks.mpeg"
import peeped from "../../sounds/peeped.mpeg"
import useSound from 'use-sound'
import { useEffect } from "react";
import { Button, Image, Form} from "react-bootstrap";

import ladyfingers from '../../sounds/ladyfingers.mp3';
import haha from '../../sounds/haha.mpeg';
import hoho from '../../sounds/hoho.mpeg';
import hohohaha from '../../sounds/hohohaha.mpeg';
import spotted from '../../sounds/spotted.mpeg';

import imgCat from '../../images/cat.jpg'
import imgFireguy from '../../images/fireguy.gif'
import imgNiko from '../../images/niko.gif'
import imgLMAO from '../../images/LMAO.jpg'
import imgPudge from '../../images/Pudge.webm'

import axios from "axios";


  
const Dota = () => {

  const volume = .6;

  const [playAssassinate] = useSound(assassinate, volume)
  const [playThanks] = useSound(thanks, volume)
  const [playPeeped] = useSound(peeped, volume)
  const [playLadyfingers] = useSound(ladyfingers, volume)
  
  const [ip, setIP] = useState('');
  const [cityState, setCityState] = useState('');
  const [ipData, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const [finished, setFinished] = useState(false);


  const [playHaha] = useSound(haha, volume)
  const [playHoho] = useSound(hoho, volume)
  const [playHohoHaha] = useSound(hohohaha, volume)
  const [playSpotted] = useSound(spotted, volume)

  const [reviewComment, setReviewComment] = useState("");
  const [reviewName, setReviewName] = useState("");

  const [reviews, setReviews] = useState([{id: 1, name: 'A', review: 'B'}, {id: 2, name: 'C', review: 'D'}])

  const onChange = (e) => {
    if(e.target.id === "inputReview"){
      setReviewComment(e.target.value);
    }
    if(e.target.id === "inputName"){
      setReviewName(e.target.value);
    }
    
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      if (reviewName !== "" && reviewComment !== ""){
        console.log(reviewName)
        console.log(reviewComment)
        setReviewComment("")
        setReviewName("")
        event.target.reset()
      }
      console.log(event)
      var message = {
        content: "New Dota Review from " + String(reviewName) + " : " + String(reviewComment)
      };
      axios.post("https://discordapp.com/api/webhooks/967122523412135988/YUPlT3r5zV5rUPF_oFAHMBnt62k2ZX8dsd9_hGdNyhemx6UJdk7xbrE6VGd_s6nLK99y", message);
      // axios.post("https://discord.com/api/webhooks/943926305005645835/rSDvhDsIWcCJWn8sFV0nIeovqB_lWOtlvtA3AYHJOvHfNFSQlIh9Zeb9Zqsr5aTjYRpX", message);


      var reviewContent = {
        'Name': reviewName,
        'Review': reviewComment,
        'IP': ip,
        'Location' : cityState
      }
      axios.post("https://www.dumboz.com/api/dota/newReview", reviewContent);
      buttonClick();
  }

  const getReviews = async()=>{
    //const data = await axios.get('https://184.18.36.83:5000/dota/points');
    const data = await axios.get('https://www.dumboz.com/api/dota/reviews');
    return(data['data']);
  }

  const getIP = async()=>{

    const data = await axios.get('https://ipapi.co/json/');
    console.log(data.data)
    setData(data.data)
    setCityState(String(data.data.city) + ", " + String(data.data.region));
    setIP(data.data.ip);

    var postContent = {
      'lat': data.data.latitude,
      'lon': data.data.longitude,
      'time': new Date(),
      'ip': data.data.ip
    }
    //axios.post("https://192.168.40.8:9999/dota/map", postContent);

    console.log(postContent)
    axios.post("https://www.dumboz.com/api/dota/map", postContent).then((response) => {
      console.log(response.status, response.data);
      if(response.data === "Successfully added"){
        var message = {
          content: "Someone just got peeped in " + String(data.data.city) + ", " + String(data.data.region)
        };
        console.log(String(cityState))
        if (String(data.data.city) + ", " + String(data.data.region) !== 'Fort Wayne, Indiana' && String(data.data.city) + ", " + String(data.data.region) !== 'Champaign, Illinois' && String(data.data.city) + ", " + String(data.data.region) !== 'Urbana, Illinois') {
          axios.post("https://discord.com/api/webhooks/1126008420944846919/lRGgFf6c2d8LrkHaewlLHyUI-dmqr8aVMgL-8uN3JSg3_rtfybMVKyYVPjXkJd_4JLcb", message);
          // axios.post("https://discord.com/api/webhooks/943926305005645835/rSDvhDsIWcCJWn8sFV0nIeovqB_lWOtlvtA3AYHJOvHfNFSQlIh9Zeb9Zqsr5aTjYRpX", message);
        }
      }
    });

    if (String(ipData['ip']).length > 20) {
      axios.post("https://discord.com/api/webhooks/943926305005645835/rSDvhDsIWcCJWn8sFV0nIeovqB_lWOtlvtA3AYHJOvHfNFSQlIh9Zeb9Zqsr5aTjYRpX", "That last ip was some whack shit.");
    }
  }

  const playRandomSound = () => {
    let sounds = [haha, hoho, hohohaha, spotted, peeped];
    let rand = Math.floor(Math.random() * sounds.length);

    switch(sounds[rand]){
      case haha:
          playHaha();
          break;
      case hoho:
          playHoho();
          break;
      case hohohaha:
          playHohoHaha();
          break;
      case spotted:
          playSpotted();
          break;
      case peeped:
          playPeeped();
          break;
      default:
          break;
    }
  }

  const buttonClick = () =>{
    setOpen(true);
    window.open('https://www.youtube.com/c/DumboZ?sub_confirmation=1', "MsgWindow", "width=600,height=700");

    playLadyfingers();
    setInterval(() => {
      playLadyfingers();
    }, 153000);

    setTimeout(() => {playThanks() }, 11000);
    setTimeout(() => {playAssassinate() }, 12000);
    setTimeout(() => {
      playPeeped() 
      setInterval(() => {
        playRandomSound();
      }, 5000);
    }, 15500);
    setTimeout(() => {
      setFinished(true);
    }, 19000);
  }

  // getIP();
  useEffect(()=>{
    getIP();
    getReviews().then(reviews => setReviews(reviews));
  },[])

  return (
    <div>
    {open ? 
    <div className="center-wrapper sunBackground">
      <div className = "sun"></div>
      <img className="sniper" src={sniper} alt="jaja"></img>
      <div id="ipDoxing"><span className="ip-span">{ip}</span>: {cityState}</div>
      {finished ?
        <>
        <Button className="btn btn-success border shadow-lg w-25 peeped-map-button" href="/dota/map">Who else got peeped?</Button>
        </>
        :
        <></>
      }
    </div>
    :
    <>
      <div id="dotaBody">
      <h1 className="zoom">u sux</h1>
      <div style={{textAlign: "center", background: "rgba(211, 211, 211, 0.744)"}}>
        <h1 className="dotaFlashing">
          congratulations you were sent this link in a dota 2 game!!!!!
          <br />
        </h1>
        <Image width="100" src = {imgLMAO} alt="pog" />(snipa POG)
      </div>

      <div
        style={{
          backgroundColor: "lightgrey",
          textAlign: "center",
          marginBottom: "3px",
        }}
      >
        <h1>SEND US A REVIEW OF THE GAME! :)</h1>

        <Form
          className = "justify-content-center dotaForm"
          id="reviewForm"
          onSubmit={handleSubmit}
        >
          <div>Name:</div>
          <Form.Control as="textarea" rows={1} onChange={onChange} id = "inputName"/>
          <div>Review:</div>
          <Form.Control as="textarea" rows={4} onChange={onChange} id = "inputReview"/>
          <input type="radio" id="lose" value="Loss" name="a" checked />
          <label htmlFor="lose">Loss</label>
          <input type="radio" id="win" value="Win" name="a" disabled />
          <label htmlFor="win">Win</label>
          <br />
          <Button id="triggerButton" type = "submit" style = {{'marginRight' : '10px'}}>Submit</Button>
          <Button id="triggerButton" onClick = {buttonClick}>See more reviews.</Button>
          <br />
        </Form>
        <br />
      </div>
      <br />

      <div id="reviews" style={{backgroundColor: "lightgrey"}}>
        <h1 style={{backgroundColor: "lightgrey", textAlign: "center"}}>
          SEE REVIEWS FROM more SATIFIED PLAYERS
        </h1>
        {reviews.map((a)=>
          <>
          <br></br>
          <div className = 'dotaContainer' id={`${a['id']}`}>
            <img src={`https://picsum.photos/800/400?random=${a['id']}`} alt = 'ja' style = {{width:"80%", filter:'blur(1px)'}}></img>
            <div className = 'dotaCentered'>
              {a['review']}
              <br></br>
              -{a['name']}
            </div>
          </div>
          <br></br>
          </>
        )}
        <hr/>
      </div>

      <div>
        <Image src={imgFireguy} alt="die" />
        <Image width="400" src={imgCat} alt="die" />
        <Image width="400" src={imgNiko} alt="die" />
      </div>
      <video
        style={{
          position: "fixed",
          zIndex: 20,
          left: 0,
          top: 0,
          width: "50%",
          pointerEvents: "none"
        }}
        width="350"
        height="350"
        loop
        muted
        autoPlay
      >
        <source src={imgPudge} type="video/webm" />
      </video>
      <h1 className="zoom">u sux</h1>
    </div></>
    }
    </div>
    
  );
};
  
export default Dota;
