import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState, useRef} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

//TODO: Make link to instructions if they already have an account.

const KevflixSignup = () => {

  const [responses, setResponses] = useState({});

  const [submitted, setSubmitted] = useState(false);

  const [state, setState] = useState(false);
  const helloRef = useRef(null);
  const goodbyeRef = useRef(null);
  const nodeRef = state ? goodbyeRef : helloRef;

  const onChange = (e) => {
      setResponses({ ...responses, [e.target.name]: e.target.value });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    var message = {
      content: String(responses['name'])+ " with gmail " + String(responses['gmail']) + "\nKnows you from: " + String(responses['reason']) + "\nWith joke: " + String(responses['joke'])
    };
    axios.post("https://discord.com/api/webhooks/943926305005645835/rSDvhDsIWcCJWn8sFV0nIeovqB_lWOtlvtA3AYHJOvHfNFSQlIh9Zeb9Zqsr5aTjYRpX", message);
    setSubmitted(true);
    setState(true);
  }


  return (
    <>
    <div className="wrapper gradientBody">
    <SwitchTransition mode = 'out-in'>
            <CSSTransition 
                key={state}
                nodeRef = {nodeRef}
                addEndListener={(done) => {
                    nodeRef.current.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
    <div ref = {nodeRef}>
    <div className="form-wrapper fader">
    {submitted ? 
      <div>
      <h2>Your stuffs been sent</h2>
      <div>Give me a little bit to add you, then either go to <a href = "http://app.plex.tv">app.plex.tv</a> or click the link on the bar above.  
      <br></br>
      <br></br>
      Then log in with your gmail auth.
      <br></br>
      <br></br>
      If you don't already have a plex account don't make one or your settings are going to be glibbergabbed.
      </div>
      </div>
    : 
    <div>
      <Form onSubmit = {handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name = "name" onChange={onChange} placeholder="Enter name" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridGmail">
          <Form.Label>Gmail</Form.Label>
          <Form.Control type="text" name = "gmail" onChange={onChange} placeholder="Gmail" />
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group>
        <Form.Label>How do I know you?</Form.Label>
        <Form.Control type="text" name = "reason" onChange={onChange} placeholder = "How do I know you?"/>
      </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group>
        <Form.Label>What is your favorite joke? (Good)</Form.Label>
        <Form.Control type="text" name = "joke" onChange={onChange} placeholder = "Joke"/>
      </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group>
        <Button variant = "primary" type = "submit">
          Submit
        </Button>
      </Form.Group>
      </Row>
    </Form>
    </div>}
    </div>
    </div>
    </CSSTransition>
        </SwitchTransition>
    </div>
    </>
  );
}

export default KevflixSignup;