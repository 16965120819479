import React from "react";
import { Map, Marker} from 'pigeon-maps'
import { osm } from 'pigeon-maps/providers'
import axios from "axios";
import { useEffect, useState } from "react";



//TODO: Make music still go in map.


const getPoints = async()=>{
    console.log('Attempting')
    //const data = await axios.get('https://184.18.36.83:5000/dota/points');
    const data = await axios.get('https://www.dumboz.com/api/dota/points');
    console.log(data['data'])
    return(data['data']);
}

const DotaMap = () => {
    const [points, setPoints] = useState([{lat: 41.09, lon: -85.13, time: 123123123}, {lat: 41.09, lon: -85.13, time: 123123123}]);
    useEffect(()=>{
       getPoints().then(points => setPoints(points));
    },[])

    return (
        <div className="wrapper">
            <Map
                provider={osm}
                defaultCenter={[41.09, -85.13]}
                defaultZoom={5}
            >
            {points.map((a) => <Marker 
                width={50}
                anchor={[a['lat'], a['lon']]} 
                color="blue"
                >
                </Marker>)
            }
            </Map>
        </div>
    );
}

export default DotaMap;
