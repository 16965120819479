import React from "react";
import {Card, Row, Col, CardGroup, Container} from "react-bootstrap";

//TODO: Shading doesn't go to bottom of page.

const About = () => {
  return (
    <>
    <div style = {{'height': '100%'}}>
    <div className="cardWrapper">
    <Container>
    <CardGroup>
    <div className="cardWrapper">
    <Row>
      <Col md="auto">
        <Card className = 'title-card gradientBody'>
          <Card.Text style={{'text-align':'center'}}>
            <h3>Who are we?</h3>
          </Card.Text>
        </Card>
      </Col>
    </Row>
    </div>
    <div className="cardWrapper">
    <Row>
      <Card className = 'about'>
        <Card.Img variant="top" src="ElijahGhent.jpg" />
        <Card.Body>
          <Card.Title>Elijah Ghent</Card.Title>
          <Card.Text>
              As the founder and CEO of Kevflix (Kracht en Vriendschap - flix), Elijah Ghent is a driven and ambitious entrepreneur with a passion for technology and a never-say-die attitude. 
              With his tireless work ethic and gift for innovation, he has built a successful company that is revolutionizing the world of
l̢͉̦͈͉̰̪̥͚̳̓̉ͥ͛͂ͩ̓̈̕ę̸̭̰̘̯̥̜ͬ̐ͯ̈́͗͟g̲̼̗̖̰̳̞̬ͥ̆̊ͦͤ̋͐̾a̰̎ͩ́̓̋ͤ̃̾̚l̷̲̞͇̜̥͎͑̀̋ͨ̓  streaming. 
              In his free time, Elijah enjoys spending time with his family and giving back to his community through various charitable efforts. 
              He's also an avid sports fan who loves cheering on his favorite teams and staying active and healthy.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className = 'about'>
        <Card.Img variant="top" src="Rhombus.png" />
        <Card.Body>
          <Card.Title>Rhombus Jones</Card.Title>
          <Card.Text>
            As the founder and CEO of DumboZ, Rhombus Jones is a creative and innovative thinker with a passion for comedy and a never-say-die attitude.
            With his tireless work ethic and gift for storytelling, he has built a successful YouTube channel that is entertaining thousands of viewers around the world. 
            In his free time, Rhombus enjoys writing, performing, and producing new and original skits, as well as spending time with his family and friends. He's also an avid animal lover who loves spending time with his furry feline companions.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className = 'about'>
        <Card.Img variant="top" src="BeepBeepMan.jpg" />
        <Card.Body>
          <Card.Title>-- --- .-. ... . / -.-. --- -.. .</Card.Title>
          <Card.Text>
            As the head writer and director of DumboZ, -- --- .-. ... . / -.-. --- -.. . is a highly skilled and talented professional with a sharp mind and a passion for innovation. 
            With his deep understanding of comedy and his ability to think outside the box, he has helped turn the company's YouTube channel into a must-see destination for fans of humor. 
            In his free time, -- --- .-. ... . enjoys writing, directing, and editing new and original skits, as well as traveling and trying new foods. He's also an avid reader who loves losing himself in a good book.
          </Card.Text>
        </Card.Body>
      </Card>
    </Row>
    </div>
    </CardGroup>
    </Container>
    </div>
    </div>
    </>
  );
};
  
export default About;