import React from "react";
import { Button, Form, Col, Row} from "react-bootstrap";
import axios from "axios";
import { useState, useRef} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

function isMobile() {
    return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
}

const Suggestions = () => {

    const [submitted, setSubmitted] = useState(false);
    const [suggestion, setSuggestion] = useState("");

    const nodeRef = useRef(null);

    const onChange = (e) => {
        setSuggestion(e.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        var message = {
          content: "Some dumbass suggested: " + String(suggestion)
        };
        axios.post("https://discordapp.com/api/webhooks/967122523412135988/YUPlT3r5zV5rUPF_oFAHMBnt62k2ZX8dsd9_hGdNyhemx6UJdk7xbrE6VGd_s6nLK99y", message);
        setSubmitted(true);
    }


    return (
        <>
        <div className="wrapper gradientBody">
        <div className="videoWrapper">
        <SwitchTransition mode = 'out-in'>
            <CSSTransition 
                key={submitted}
                nodeRef = {nodeRef}
                addEndListener={(done) => {
                    nodeRef.current.addEventListener("transitionend", done, false);
                }}
                classNames="shrink"
            >
            <div ref = {nodeRef}>
            <div>
            {submitted ? 
            <div id="suggestionHeader" className="suggestionHeader shrinker">we <span className="fun">DON'T</span> care</div>  
            : 
            <>
            <div id="suggestionHeader" className="suggestionHeader shrinker">we <span className="fun">don't</span> care</div>
            <Form className = "suggestionForm justify-content-center" onSubmit={handleSubmit}>
                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={5} placeholder = "Send a Suggestion" onChange={onChange}/>
                    </Form.Group>
                </Row>
                <Row className="d-flex justify-content-center"> 
                    <Col xs = "auto">
                    <Button type = "submit" variant="secondary">
                        Submit
                    </Button>
                    </Col>
                </Row>
            </Form>
            </>
            }
            </div>
            </div>
            </CSSTransition>
        </SwitchTransition>
        
        
        {isMobile() ?
        <img className = "fill" src = 'weirdPoster.png' alt = '' />
        :
        <video className='fill' autoPlay loop muted playsInline>
            <source src="weird.webm" type='video/webm' />
        </video>
        }
        
        </div>
        </div>
        </>
    );
}

export default Suggestions;